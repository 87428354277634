var altoPantalla = jQuery(window).height();
var anchoPantalla = jQuery(window).width();
var altoHeader = jQuery('header').height() + 20;

function altoMenuMask() {
	jQuery('.menuMask').height(altoPantalla - altoHeader).css('top', altoHeader);
}

function margenHov() {
	jQuery('.prodServContainer > div').each(function () {
		var altoDiv = jQuery(this).height();
		var altoH3 = jQuery(this).find('h3').height();
		jQuery(this).find('h3').css('margin-top', altoDiv - altoH3);
		jQuery(this).hover(
			function () {
				jQuery(this).find('h3').css('margin-top', '25px');

			},
			function () {
				jQuery(this).find('h3').css('margin-top', altoDiv - altoH3);
			});
	});
}

function alturaMaximaSliderHome() {
	var alturaMaxima = altoPantalla - jQuery('header').outerHeight() - 100;
	// jQuery('.carouselContainer .item .container').css('height', alturaMaxima);
}

jQuery(document).ready(function () {
	if(jQuery('.wpcf7-form').length > 0){
		jQuery('.wpcf7-submit').click(function(){
			jQuery('.wpcf7-form').submit();
		});
	}
	jQuery('.infraCont ul li img').wrap('<span></span>');
	jQuery('.nuestroCentrosContainer .dropdown button').click(function () {
		jQuery('html, body').animate({
			scrollTop: jQuery('#moveToBoxes').offset().top
		}, 750);
	});
	// Despliega formulario lateral
	jQuery('.contactTab').click(function () {
		jQuery(this).toggleClass('active');
		jQuery('.contactTabContainer').toggleClass('active');
	});
	// Ajusta la flotacion de items en pagina de centros
	var counter = 0;
	jQuery('.landingCentrosCont > div').each(function () {
		if (counter < 3) {
			counter++;
		} else {
			counter = 1;
		}
		jQuery(this).addClass('colN0' + counter);
	});
	jQuery('.landingCentrosCont > div:even').addClass('even');
	jQuery('.landingCentrosCont > div:odd').addClass('odd');

	// Despliega contenido con scroll
	jQuery(window).scroll(function () {
		jQuery('.displayRight').each(function (i) {
			var bottom_of_objecta = jQuery(this).offset().top + (jQuery(this).outerHeight() * .1);
			var bottom_of_windowa = jQuery(window).scrollTop() + jQuery(window).height();
			if (bottom_of_windowa > bottom_of_objecta) {
				TweenLite.to(this, 2, {
					x: 0,
					autoAlpha: 1,
					ease: Power4.easeOut
				});
			} else if (bottom_of_windowa < bottom_of_objecta) {
				TweenLite.to(this, 2, {
					x: 30,
					autoAlpha: 0,
					ease: Power4.easeOut
				});
			}
		});
	});
	jQuery(window).scroll(function () {
		jQuery('.displayLeft').each(function (i) {
			var bottom_of_objecta = jQuery(this).offset().top + (jQuery(this).outerHeight() * .1);
			var bottom_of_windowa = jQuery(window).scrollTop() + jQuery(window).height();
			if (bottom_of_windowa > bottom_of_objecta) {
				TweenLite.to(this, 2, {
					x: 0,
					autoAlpha: 1,
					ease: Power4.easeOut
				});
			} else if (bottom_of_windowa < bottom_of_objecta) {
				TweenLite.to(this, 2, {
					x: -30,
					autoAlpha: 0,
					ease: Power4.easeOut
				});
			}
		});
	});
	jQuery(window).scroll(function () {
		jQuery('.hideme').each(function (i) {
			var bottom_of_objecta = jQuery(this).offset().top + (jQuery(this).outerHeight() * .1);
			var bottom_of_windowa = jQuery(window).scrollTop() + jQuery(window).height();
			if (bottom_of_windowa > bottom_of_objecta) {
				TweenLite.to(this, 2, {
					y: 0,
					autoAlpha: 1,
					ease: Power4.easeOut
				});
			} else if (bottom_of_windowa < bottom_of_objecta) {
				TweenLite.to(this, 2, {
					y: 60,
					autoAlpha: 0,
					ease: Power4.easeOut
				});
			}
		});
	});
	jQuery(window).ready(function () {
		jQuery('.hideme').each(function (i) {
			var bottom_of_objecta = jQuery(this).offset().top + (jQuery(this).outerHeight() * .1);
			var bottom_of_windowa = jQuery(window).scrollTop() + jQuery(window).height();
			if (bottom_of_windowa > bottom_of_objecta) {
				TweenLite.to(this, 2, {
					y: 0,
					autoAlpha: 1,
					ease: Power4.easeOut
				});
			} else if (bottom_of_windowa < bottom_of_objecta) {
				TweenLite.to(this, 2, {
					y: 60,
					autoAlpha: 0,
					ease: Power4.easeOut
				});
			}
		});
	});

	// Precarga
	/*
	jQuery(window).load(function () {
		jQuery('.preload').fadeOut('slow', function () {});
	});
	jQuery(window).bind('beforeunload', function () {
		jQuery('.preload').fadeIn('fast');
	});
	*/
	jQuery('.prodServContainer > div').each(function (i) {
		jQuery(this).addClass('itemN0' + (i + 1));
	});

	jQuery('.aboutSecundario').each(function (i) {
		jQuery(this).addClass('itemN0' + (i + 1));
	});

	//Modifica html de menu principal
	jQuery('#menu-menu-principal > li > a, #menu-menu-principal-1 > li > a, #menu-menu-principal-2 > li > a').each(function () {
		var text = jQuery(this).html().split(' '),
			len = text.length,
			result = [];
		for (var i = 0; i < len; i++) {
			result[i] = '<span>' + text[i] + '</span>';
		}
		jQuery(this).html(result.join(' '));
	});
	jQuery('#menu-menu-principal > li > a, #menu-menu-principal-1 > li > a, #menu-menu-principal-2 > li > a').each(function () {
		jQuery(this).find('span').each(function (i) {
			jQuery(this).addClass('itemN0' + (i + 1));
		});
	});

	// Despliega el menu fixed
	jQuery(window).scroll(function () {
		if (jQuery(this).scrollTop() > 150) {
			jQuery('.headerFixed').css('top', '0px');
			jQuery('.headerFixed #menu-menu-principal > li > .sub-menu').css('top', '69px');
		} else {
			jQuery('.headerFixed').css('top', '-100px');
			jQuery('.headerFixed #menu-menu-principal > li > .sub-menu').css('top', '138px');
		}
		if (jQuery(this).scrollTop() > 600) {
			jQuery('.home .contactTab').css('opacity', '1');
		} else {
			jQuery('.home .contactTab').css('opacity', '0');
		}
	});

	// Crea slider de imagenes home
	jQuery('.carousel').carousel();
	jQuery('.carousel-indicators li:first-child').addClass('active');
	jQuery('.carousel-inner .item:first-child').addClass('active');

	// Crea estructura de pagina de noticias
	jQuery('.landingNewsCont > div:first-child').addClass('notDestacada').removeClass('notNormal col-sm-6');
	jQuery('.notDestacada .singleNew').addClass('row');
	jQuery('.notDestacada .imgCont').addClass('col-xs-12 col-sm-12 col-md-6');
	jQuery('.notDestacada .contCont').addClass('col-xs-12 col-sm-12 col-md-6');

	jQuery('.notNormal .contContHeader').each(function () {
		var contContHeader = jQuery(this).html();
		jQuery(this).parent().parent().find('.imgCont').append('<div class="contContHeader">' + contContHeader + '<div>');
		jQuery(this).remove();
	});

	jQuery('.landingNewsCont > div').each(function (i) {
		jQuery(this).addClass('itemN0' + (i + 1));
	});

	// Landing centros
	jQuery('.singleCentro').each(function () {
		jQuery(this).find('.singleCaract').each(function (i) {
			jQuery(this).addClass('itemN0' + (i + 1));
		});
	});

	jQuery('.singleProdServConct .singleProdServ').each(function (i) {
		jQuery(this).addClass('itemN0' + (i + 1));
	});

	jQuery('.landingCentrosdeDistCont .singleCentroDeDist').each(function (i) {
		jQuery(this).addClass('itemN0' + (i + 1));
	});

	jQuery('.landingCentrosCont .singleCentro').each(function (i) {
		jQuery(this).addClass('itemN0' + (i + 1));
	});

	// Link ver mapa
	jQuery('.caractContainer .singleCaract').each(function (i) {
		jQuery(this).addClass('itemN0' + (i + 1));
	});
	jQuery('.singleCaract.itemN01 span').append('<a href="#sectionUbic">Ver mapa</a>');
	jQuery('.singleCaract a').click(function (evn) {
		evn.preventDefault();
		jQuery('html,body').scrollTo(this.hash, this.hash).css('margin-top', '50px');
	});
	jQuery('.caractContainer .itemN01, .caractContainer .itemN02, .caractContainer .itemN03').removeClass('col-xs-12 col-sm-4 col-md-3').wrapAll('<div class="col-xs-12 col-sm-4 col-md-3"></div>');

	// Crea slider red megacentro
	jQuery('#redCarousel').carouFredSel({
		responsive: true,
		auto: false,
		items: {
			visible: {
				min: 1,
				max: 6
			}
		},
		prev: '#prevRed',
		next: '#nextRed',
		scroll: {
			items: 1,
			duration: 1000,
			pauseOnHover: true,
			easing: 'quadratic'
		},
		swipe: {
			onTouch: true
		}
	});

	// Crea slider clientes
	jQuery('#clientesCarousel').imagesLoaded(function () {
		jQuery('#clientesCarousel').carouFredSel({
			responsive: true,
			items: {
				visible: {
					min: 1,
					max: 6
				}
			},
			prev: '#prevCliente',
			next: '#nextCliente',
			scroll: {
				items: 1,
				duration: 750,
				pauseOnHover: true,
				easing: 'quadratic'
			},
			swipe: {
				onTouch: true
			}
		});
	});

	// Crea slider Centros de distribución
	jQuery('#centCarousel').imagesLoaded(function () {
		jQuery('#centCarousel').carouFredSel({
			responsive: true,
			items: {
				visible: {
					min: 1,
					max: 3
				}
			},
			prev: '#prevCent',
			next: '#nextCent',
			scroll: {
				items: 1,
				duration: 1000,
				pauseOnHover: true,
				easing: 'quadratic'
			},
			swipe: {
				onTouch: true
			}
		});
	});

	margenHov();

	jQuery(window).resize(function () {
		margenHov();
	});

	if (jQuery(window).width() > 991) {
		// Crea estructura de pagina de productos y servicios
		jQuery('.singleProdServ:even').addClass('even');
		jQuery('.singleProdServ:odd').addClass('odd');
		jQuery('.singleProdServ.odd .col-md-5').each(function () {
			jQuery(this).prependTo(this.parentNode);
		});
	}
	if (jQuery(window).width() < 992) {
		jQuery('.despliegaPanel').click(function () {
			jQuery(this).find('i').toggleClass('activeClose');
			if (jQuery(this).find('i').hasClass('activeClose')) {
				jQuery(this).find('i').removeClass('ion-android-menu').addClass('ion-android-close');
			} else {
				jQuery(this).find('i').addClass('ion-android-menu').removeClass('ion-android-close');
			}
			jQuery('body').toggleClass('stopScrolling');
			jQuery('.mainContainer').toggleClass('moveMainCont');
			jQuery('.panelLateral').toggleClass('movePanelLateral');
		});
		jQuery('.itemPadre').append('<span class="showMovilSubMenu"><i class="ion-android-add"></i></span>');
		jQuery('.itemPadre > .sub-menu').addClass('primerHijo');
		jQuery('.showMovilSubMenu').click(function () {
			jQuery(this).parent().find('.primerHijo').slideToggle(500);
			jQuery(this).parent().toggleClass('active');
			jQuery(this).find('i').toggleClass('ion-android-remove');
		});
	}
	if (jQuery(window).width() < 768) {

		// Touch para carrusel principal
		jQuery('#myCarousel').swipe({
			swipe: function (event, direction, distance, duration, fingerCount, fingerData) {
				if (direction == 'left') jQuery(this).carousel('next');
				if (direction == 'right') jQuery(this).carousel('prev');
			},
			allowPageScroll: "vertical"
		});

		var phoneNumber = jQuery('.singleCaract.itemN03 span').html();
		jQuery('.singleCaract.itemN03 span').wrapAll('<a href="tel:' + phoneNumber + '" class="pColor"></a>');

		jQuery('#galeriaCarousel li div').each(function () {
			jQuery(this).height(anchoPantalla - 30);
		});

		var testMargen = anchoPantalla - 30;

		// Crea carousel soluciones
		jQuery('#solucionesCarousel').imagesLoaded(function () {
			jQuery('#solucionesCarousel').carouFredSel({
				responsive: true,
				items: 1,
				prev: '#prevSolucion',
				next: '#nextSolucion',
				scroll: {
					items: 1,
					duration: 1000,
					pauseOnHover: true,
					easing: 'quadratic'
				},
				swipe: {
					onTouch: true
				}
			});
		});

		// Crea carousel galeria de imagenes
		jQuery('#galeriaCarousel').carouFredSel({
			responsive: true,
			width: '100%',
			items: 1,
			auto: false,
			prev: '#prevGalCar',
			next: '#nextGalCar',
			scroll: {
				items: 1,
				duration: 1000,
				pauseOnHover: true,
				easing: 'quadratic'
			},
			swipe: {
				onTouch: true
			}
		});
		jQuery('#galeriaCarousel li').css('float', 'left');

		// Despliega colapsable nuestros centros
		jQuery('.despliegaDropMovil').click(function () {
			jQuery(this).parent().find('ul').slideToggle('fast');
		});
	} else {

		// Despliega menu secundario
		jQuery('.itemPadre > a').click(function () {
			jQuery(this).parent().find('.sub-menu').fadeToggle('300');
			jQuery(this).attr('href', '#');
			jQuery('.menuMask').fadeToggle('300');
			jQuery('body').toggleClass('stopScrolling');
		});
		jQuery('.itemPadre > a').click(function (event) {
			event.preventDefault();
			return false;
		});
		jQuery('.menuMask').click(function () {
			jQuery(this).fadeOut('300');
			jQuery('.itemPadre .sub-menu').fadeOut('300');
			jQuery('body').removeClass('stopScrolling');
		});

		// Scripts para galeria de imagenes
		var itemsLi = jQuery('.galCont li');
		for (var i = 0; i < itemsLi.length; i += 5) {
			itemsLi.slice(i, i + 5).wrapAll('<div class="rowContenedor"></div>');
		}
		jQuery('.rowContenedor').each(function (i) {
			var itemsContador = jQuery(this).find('li').length;
			jQuery(this).find('li').each(function (i) {
				jQuery(this).addClass('itemN0' + (i + 1));
			});
			if (itemsContador == 1) {
				jQuery('.rowContenedor .itemN01').addClass('col-xs-12 hideme');
			}
			if (itemsContador == 2) {
				jQuery('.rowContenedor .itemN01').addClass('col-xs-12 col-sm-6 hideme');
				jQuery('.rowContenedor .itemN02').addClass('col-xs-12 col-sm-6 hideme');
			}
			if (itemsContador == 3) {
				jQuery('.rowContenedor .itemN01').addClass('col-xs-12 col-sm-6 hideme');
				jQuery('.rowContenedor .itemN02').addClass('col-xs-12 col-sm-6 hideme');
				jQuery('.rowContenedor .itemN03').addClass('col-xs-12 hideme');
			}
			if (itemsContador == 4) {
				jQuery('.rowContenedor .itemN01').addClass('col-xs-12 col-sm-4 hideme');
				jQuery('.rowContenedor .itemN02').addClass('col-xs-12 col-sm-8 hideme');
				jQuery('.rowContenedor .itemN03').addClass('col-xs-12 col-sm-8 hideme');
				jQuery('.rowContenedor .itemN04').addClass('col-xs-12 col-sm-4 hideme');
			}
			if (itemsContador == 5) {
				jQuery('.rowContenedor .itemN01').addClass('col-xs-12 col-sm-6 hideme');
				jQuery('.rowContenedor .itemN02').addClass('col-xs-12 col-sm-6 hideme');
				jQuery('.rowContenedor .itemN03').addClass('col-xs-12 col-sm-4 hideme');
				jQuery('.rowContenedor .itemN04').addClass('col-xs-12 col-sm-4 hideme');
				jQuery('.rowContenedor .itemN05').addClass('col-xs-12 col-sm-4 hideme');
			}
		});

		// Crea estructura de pagina Centros de distribución
		jQuery('.singleCentroDeDist:even').addClass('even');
		jQuery('.singleCentroDeDist:odd').addClass('odd');
		jQuery('.singleCentroDeDist.odd .cambiaLugar').each(function () {
			jQuery(this).prependTo(this.parentNode);
		});
	}

});
